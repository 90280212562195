import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowRightIcon, ClockIcon, XMarkIcon, CurrencyDollarIcon, MapPinIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

const AvailableRoutes = () => {
  const { routes, links, companySlug } = useOutletContext();
  const [schedulesByLink, setSchedulesByLink] = useState({});
  const [loading, setLoading] = useState(true);
  const [randomRouteLinkSchedules, setRandomRouteLinkSchedules] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [pickupDropoff, setPickupDropoff] = useState({ pickup: null, dropoff: null });
  const [routeNote, setRouteNote] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [travelDate, setTravelDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [passengers, setPassengers] = useState(1);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL || 'https://admin.kuvaway.com';

  // Generar combinaciones de route, link y horario
  const generateRouteLinkSchedules = () => {
    return routes.flatMap((route) =>
      links[route.route_id]?.flatMap((link) => {
        const schedules = schedulesByLink[link.link_id] || [];
        if (schedules.length === 0) {
          return [{
            routeId: route.route_id,
            routeName: route.route_name,
            linkId: link.link_id,
            linkName: link.link_name,
            price: link.price || 'N/A',
            departureTime: 'N/A',
            tripType: 'one_way',
          }];
        }
        return schedules.map((schedule) => ({
          routeId: route.route_id,
          routeName: route.route_name,
          linkId: link.link_id,
          linkName: link.link_name,
          price: link.price || 'N/A',
          departureTime: schedule.schedule_time || 'N/A',
          tripType: schedule.trip_type || 'one_way',
        }));
      }) || []
    );
  };

  // Seleccionar 12 combinaciones aleatorias
  const getRandomRouteLinkSchedules = (arr, num) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, Math.min(num, arr.length));
  };

  // Obtener horarios para cada link
  useEffect(() => {
    const fetchSchedules = async () => {
      setLoading(true);
      const schedulesData = { ...schedulesByLink };
      const allLinks = routes.flatMap((route) => links[route.route_id] || []);

      await Promise.all(
        allLinks.map(async (link) => {
          if (schedulesData[link.link_id]) return;
          try {
            const response = await fetch(`${API_URL}/api/schedules/${link.link_id}`);
            if (!response.ok) {
              console.error(`Error fetching schedules for link_id ${link.link_id}: ${response.status}`);
              schedulesData[link.link_id] = [];
              return;
            }
            const schedules = await response.json();
            schedulesData[link.link_id] = schedules;
          } catch (error) {
            console.error(`Error fetching schedules for link_id ${link.link_id}:`, error);
            schedulesData[link.link_id] = [];
          }
        })
      );

      setSchedulesByLink(schedulesData);
      setLoading(false);
    };

    if (routes.length > 0 && Object.keys(links).length > 0) {
      fetchSchedules();
    }
  }, [routes, links]);

  // Actualizar combinaciones cuando los horarios estén listos
  useEffect(() => {
    if (!loading) {
      const allRouteLinkSchedules = generateRouteLinkSchedules();
      const randomSelection = getRandomRouteLinkSchedules(allRouteLinkSchedules, 12);
      setRandomRouteLinkSchedules(randomSelection);
    }
  }, [loading, schedulesByLink]);

  // Fetch de horarios y pickup/dropoff cuando se selecciona una ruta
  useEffect(() => {
    if (!selectedRoute) {
      setSchedules([]);
      setPickupDropoff({ pickup: null, dropoff: null });
      setRouteNote(null);
      return;
    }

    const fetchSchedules = async () => {
      try {
        const response = await fetch(`${API_URL}/api/schedules/${selectedRoute.linkId}`);
        if (!response.ok) throw new Error(`Error fetching schedules: ${response.status}`);
        const data = await response.json();
        setSchedules(data);
      } catch (error) {
        console.error('Error fetching schedules:', error);
        setSchedules([]);
      }
    };

    const fetchPickupDropoff = async () => {
      try {
        const response = await fetch(`${API_URL}/api/pickup-dropoff/${selectedRoute.linkId}`);
        if (!response.ok) throw new Error(`Error fetching pickup/dropoff: ${response.status}`);
        const result = await response.json();
        if (result.success && result.data) {
          const pickup = result.data.find((item) => item.type === 'pickup') || null;
          const dropoff = result.data.find((item) => item.type === 'dropoff') || null;
          setPickupDropoff({ pickup, dropoff });
        } else {
          setPickupDropoff({ pickup: null, dropoff: null });
        }
      } catch (error) {
        console.error('Error fetching pickup/dropoff:', error);
        setPickupDropoff({ pickup: null, dropoff: null });
      }
    };

    const fetchRouteNote = async () => {
      try {
        const res = await fetch(`${API_URL}/route-notes/${selectedRoute.routeId}`);
        const data = await res.json();
        if (data?.note) setRouteNote(data.note);
      } catch (error) {
        console.error('Error fetching route note:', error);
      }
    };

    fetchSchedules();
    fetchPickupDropoff();
    fetchRouteNote();
  }, [selectedRoute, API_URL]);

  const handleSelect = (item) => {
    setSelectedRoute(item);
  };

  const handleClosePanel = () => {
    if (showForm) {
      setShowForm(false);
    } else {
      setSelectedRoute(null);
      setShowForm(false);
    }
  };

  const handleConfirm = () => {
    const selectedSchedule = schedules.find((s) => s.schedule_time === selectedTime);

    const purchaseData = {
      routeId: selectedRoute.routeId,
      linkId: selectedRoute.linkId,
      scheduleId: selectedSchedule?.schedule_id || null,
      departureTime: selectedTime,
      travelDate,
      returnDate: returnDate || null,
      passengers,
      routeName: selectedRoute.routeName,
      toName: selectedRoute.linkName,
      price: selectedRoute.price,
    };

    navigate(`/${companySlug}/checkout`, { state: { purchaseData, companySlug } });
  };

  const formatTime = (time) => {
    if (!time || time === 'N/A') return 'N/A';
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours, 10);
    const period = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minutes} ${period}`;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 640, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const pickupLocation = pickupDropoff.pickup ? pickupDropoff.pickup.effective_pickup : 'N/A';
  const dropoffLocation = pickupDropoff.dropoff ? pickupDropoff.dropoff.effective_dropoff : 'N/A';
  const isPickupConfigured = pickupDropoff.pickup?.is_active === 1 && pickupDropoff.pickup.admin_pickup;
  const isDropoffConfigured = pickupDropoff.dropoff?.is_active === 1 && pickupDropoff.dropoff.admin_dropoff;

  return (
    <section className="w-full py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-4 text-gray-900">
          Popular Routes
        </h2>
        <p className="text-center text-gray-600 mb-6">
        Discover the most popular routes between San José, Montezuma, Santa Teresa, and Guanacaste. Book quickly and easily, and receive your ticket directly in your inbox.
        </p>
        {randomRouteLinkSchedules.length > 0 ? (
          <Slider {...settings}>
            {randomRouteLinkSchedules.map((item, index) => (
              <div key={`${item.routeId}-${item.linkId}-${item.departureTime}-${index}`} className="p-4">
                <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col justify-between h-72">
                  <div>
                    <div className="flex justify-between items-start">
                      <h3 className="text-base font-semibold text-gray-900 mb-2">
                        {item.routeName}
                      </h3>
                      <div className="text-right ml-4">
                        <span className="text-[18px] font-bold" style={{ color: '#2E4A9B' }}>
                          ${item.price}
                        </span>
                        <p className="text-gray-600 text-sm">Desde/</p>
                      </div>
                    </div>
                    <div className="flex items-center mb-3">
                      <ArrowRightIcon className="h-5 w-5 text-gray-500 mr-2" />
                      <span className="text-gray-700 font-medium">
                        to {item.linkName}
                      </span>
                    </div>
                    <div className="flex items-center mb-3">
                      <ClockIcon className="h-5 w-5 text-gray-500 mr-2" />
                      <span className="text-gray-700">
                        {loading ? 'Loading...' : `Departure: ${formatTime(item.departureTime)}`}
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => handleSelect(item)}
                      className="mt-4 px-4 py-2 text-white font-semibold rounded-full shadow hover:brightness-110 transition-colors w-full"
                      style={{ backgroundColor: 'oklch(0.424 0.199 265.638)' }}
                    >
                      Select
                    </button>
                    <p className="text-center text-gray-600 text-sm mt-2">
                      per person
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p className="text-center text-gray-500">
            {loading ? 'Loading routes...' : 'No routes available.'}
          </p>
        )}
        {selectedRoute && (
          <>
            <div className="fixed inset-y-0 right-0 w-full sm:w-96 bg-white shadow-2xl transform transition-transform duration-300 ease-in-out z-50 translate-x-0">
              <div className="h-full flex flex-col">
                <div className="p-6 border-b border-gray-200 flex justify-between items-center bg-gradient-to-r from-blue-50 to-white">
                  <h3 className="text-xl font-bold text-gray-900">Route Information</h3>
                  <button onClick={handleClosePanel} className="text-gray-500 hover:text-gray-700">
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
                <div className="flex-grow p-6 overflow-y-auto">
                  <div className="space-y-6">
                    {!showForm && routeNote && (
                      <div className="border-l-4 border-blue-800 bg-blue-50 p-4 text-sm text-blue-900 flex items-start gap-2 rounded-md">
                        <InformationCircleIcon className="h-5 w-5 mt-0.5" />
                        <p>{routeNote}</p>
                      </div>
                    )}
                    <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                      <h4 className="text-lg font-semibold text-gray-800 mb-3">Details</h4>
                      <div className="space-y-3">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <ArrowRightIcon className="h-5 w-5 text-gray-500" />
                            <span className="text-gray-600 font-medium">From:</span>
                          </div>
                          <p className="text-gray-900 font-semibold">{selectedRoute.routeName}</p>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <ArrowRightIcon className="h-5 w-5 text-gray-500" />
                            <span className="text-gray-600 font-medium">To:</span>
                          </div>
                          <p className="text-gray-900 font-semibold">{selectedRoute.linkName}</p>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <CurrencyDollarIcon className="h-5 w-5 text-gray-500" />
                            <span className="text-gray-600 font-medium">Price:</span>
                          </div>
                          <p className="text-gray-900 font-semibold">${selectedRoute.price}</p>
                        </div>
                      </div>
                    </div>
                    {!showForm && (
                      <>
                        <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                          <h4 className="text-lg font-semibold text-gray-800 mb-1">Available Schedules</h4>
                          <p className="text-sm text-gray-500 mb-3">Horarios de salida disponibles para esta ruta</p>
                          {schedules.length > 0 ? (
                            <ul className="space-y-2">
                              {schedules.map((schedule, index) => (
                                <li key={index} className="flex items-center space-x-2">
                                  <ClockIcon className="h-5 w-5 text-gray-500" />
                                  <span className="text-gray-700">{schedule.schedule_time}</span>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="text-gray-500">No schedules available for this route.</p>
                          )}
                        </div>
                        <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                          <h4 className="text-lg font-semibold text-gray-800 mb-1">Pickup/Dropoff Information</h4>
                          <p className="text-sm text-gray-500 mb-3">Pickup and dropoff locations for this route</p>
                          <div className="space-y-4">
                            <div>
                              <h5 className="text-md font-semibold text-gray-700 mb-2">Pickup</h5>
                              {pickupDropoff.pickup ? (
                                <div className="space-y-2">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                      <MapPinIcon className="h-5 w-5 text-gray-500" />
                                      <span className="text-gray-600 font-medium">Location:</span>
                                    </div>
                                    <p className="text-gray-900 font-semibold">{pickupLocation}</p>
                                  </div>
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                      <MapPinIcon className="h-5 w-5 text-gray-500" />
                                      <span className="text-gray-600 font-medium">Source:</span>
                                    </div>
                                    <p className="text-gray-900 font-semibold">
                                      {isPickupConfigured ? 'Configured' : 'Default'}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <p className="text-gray-500">Pickup point has not been specified. Please be ready at the main road near <span className='font-semibold'>{selectedRoute.routeName}</span>.</p>
                              )}
                            </div>
                            <div>
                              <h5 className="text-md font-semibold text-gray-700 mb-2">Dropoff</h5>
                              {pickupDropoff.dropoff ? (
                                <div className="space-y-2">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                      <MapPinIcon className="h-5 w-5 text-gray-500" />
                                      <span className="text-gray-600 font-medium">Location:</span>
                                    </div>
                                    <p className="text-gray-900 font-semibold">{dropoffLocation}</p>
                                  </div>
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                      <MapPinIcon className="h-5 w-5 text-gray-500" />
                                      <span className="text-gray-600 font-medium">Source:</span>
                                    </div>
                                    <p className="text-gray-900 font-semibold">
                                      {isDropoffConfigured ? 'Configured' : 'Default'}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <p className="text-gray-500">Dropoff point is not defined. The driver will drop you off along the main road near <span className='font-semibold'>{selectedRoute.linkName}</span>.</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {showForm && (
                      <div className="bg-gray-50 p-4 rounded-lg shadow-sm space-y-4">
                        <h4 className="text-lg font-semibold text-gray-800 mb-1">Trip Details</h4>
                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">When are you traveling?</label>
                            <input
                              type="date"
                              className="w-full border px-3 py-2 rounded-lg"
                              value={travelDate}
                              onChange={(e) => setTravelDate(e.target.value)}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Select departure time</label>
                            <select
                              className="w-full border px-3 py-2 rounded-lg"
                              value={selectedTime}
                              onChange={(e) => setSelectedTime(e.target.value)}
                            >
                              <option value="">Select time</option>
                              {schedules.map((s, index) => (
                                <option key={index} value={s.schedule_time}>{s.schedule_time}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Return date (optional)</label>
                            <input
                              type="date"
                              className="w-full border px-3 py-2 rounded-lg"
                              value={returnDate}
                              onChange={(e) => setReturnDate(e.target.value)}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">How many passengers?</label>
                            <input
                              type="number"
                              className="w-full border px-3 py-2 rounded-lg"
                              min="1"
                              value={passengers}
                              onChange={(e) => setPassengers(Number(e.target.value))}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="p-6 border-t border-gray-200 flex justify-between gap-4">
                  <button
                    onClick={handleClosePanel}
                    className="w-1/2 px-4 py-3 bg-gray-200 text-gray-700 font-semibold rounded-full shadow hover:bg-gray-300 transition-colors duration-300"
                  >
                    Close
                  </button>
                  {!showForm ? (
                    <button
                      onClick={() => setShowForm(true)}
                      className="w-1/2 px-4 py-3 bg-blue-800 text-white font-semibold rounded-full shadow hover:bg-blue-900 transition-colors duration-300"
                    >
                      Reserve
                    </button>
                  ) : (
                    <button
                      onClick={handleConfirm}
                      disabled={!travelDate || passengers < 1 || !selectedTime}
                      className="w-1/2 px-4 py-3 bg-blue-800 text-white font-semibold rounded-full shadow hover:bg-blue-900 transition-colors duration-300 disabled:opacity-50"
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={handleClosePanel}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default AvailableRoutes;